<template>
  <BaseViewWrapper>
    <StaffProfileDashboard>
      <template #header>
        <BaseHeader>
          <BasePageTitle>
            Viewing Your Profile
          </BasePageTitle>
        </BaseHeader>
      </template>

      <template
        v-if="rosterable"
        #preferences
      >
        <StaffPreferences
          :loading="loadingProfile"
          :initial-preferred="preferred"
          :initial-unavailable="unavailable"
          :user-id="userId"
        />
      </template>
      
      <template #details>
        <StaffDetails
          :details-array="detailsArray"
          :entitlement-array="entitlementArray"
          :admin-array="adminArray"
          :user-id="userId"
          :loading="loadingProfile"
        />
      </template>
    </StaffProfileDashboard>
  </BaseViewWrapper>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import useToasts from '@/hooks/useToasts'
import useRole from '@/hooks/useRole'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import moment from 'moment'

// Types
import { UserPreferences, UserSuccessResponse } from '@/types/users'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// Components
import StaffProfileDashboard from '@/components/staff/layouts/StaffProfileDashboard.vue'
import StaffPreferences from '@/components/staff/StaffPreferences.vue'
import StaffDetails from '@/components/staff/StaffDetails.vue'

export default defineComponent({
  components: {
    StaffProfileDashboard,
    StaffPreferences,
    StaffDetails,
  },

  setup () {
    const store = useStore()
    const dispatch = useToasts()
    const role = useRole()
    
    const profile = ref<UserSuccessResponse & UserPreferences | null>(null)
    const loadingProfile = ref(true)

    const userId = computed(() => store.getters[UserGetters.USER_ID].toString())

    UsersAPI.show(userId.value)
        .then((res) => {
          profile.value = res.data
        })
        .catch((err) => {
          dispatch.errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          loadingProfile.value = false
        })

    const rosterable = computed(() => {
      if (loadingProfile.value || !profile.value) return true

      return profile.value.rosterable
    })

    const detailsArray = computed(() => {
      if (loadingProfile.value || !profile.value) return []

      const rosterables = profile.value.sub_grade ? [
        { label: 'Grade', value: profile.value.sub_grade ?? 'n/a' },
        { label: 'Primary Rota', value: profile.value.primary_rota?.label ?? 'n/a' },
        { label: 'Contract', value: `${profile.value.contract?.slug.toUpperCase()} ${profile.value.contract_value ?? ''}`},
      ] : []

      return [
        { label: 'Name', value: profile.value?.name },
        { label: 'DOB', value: moment(profile.value.dob).format('DD/MM/YYYY') },
        { label: 'Phone', value: profile.value.phone },
        { label: 'Email', value: profile.value.email },
        ...rosterables,
      ]
    })

    const entitlementArray = computed(() => {
      if (loadingProfile.value || !profile.value || !profile.value.sub_grade) return []

      return [
        { label: 'Annual Leave', value: profile.value.annual_leave },
        { label: 'Study Leave', value: profile.value.study_leave },
        { label: 'Parental Leave', value: profile.value.parental_leave },
      ]
    })

    const adminArray = computed(() => {
      if (loadingProfile.value || !profile.value || role.isStaff.value) return []

      return [
        { label: 'Role', value: capitalizeFirstLetter(profile.value?.role) },
      ]
    })

    const preferred = computed(() => {
      if (loadingProfile.value || !profile.value) return []
      return profile.value.preferred
    })

    const unavailable = computed(() => {
      if (loadingProfile.value || !profile.value) return []
      return profile.value.unavailable
    })
    
    return {
      userId,
      rosterable,
      preferred,
      unavailable,
      loadingProfile,
      detailsArray,
      entitlementArray,
      adminArray,
    }
  },
})
</script>
