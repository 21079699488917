<template>
  <div class="mb-2">
    <slot name="header" />
  </div>
  <div class="grid-container min-h-70vh">
    <section class="item-preferences">
      <slot name="preferences" />
    </section>
    <section class="item-details">
      <slot name="details" />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>

<style lang="scss" scoped>
.item-preferences {
  grid-area: preferences;
}

.item-details {
  grid-area: details;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  grid-template-rows: auto;
  grid-template-areas:
    "details"
    "preferences";

  @screen lg {
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    grid-template-areas:
      "details preferences"
      "details preferences"
      ". .";
  }

  @screen xl {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "details preferences ."
      "details preferences ."
      ". . .";
  }

  @screen xxl {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 0.5rem;
    grid-template-areas:
      "details preferences . ."
      "details preferences . ."
      ". . . .";
  }
}
</style>